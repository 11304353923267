import {
  applyPolyfills,
  defineCustomElements as defineInputElements,
} from '@ispui/input/loader';
import { defineCustomElements as defineButtonElements } from '@ispui/button/loader';
import { defineCustomElements as defineSelectElements } from '@ispui/select/loader';
import { defineCustomElements as defineTooltipElements } from '@ispui/tooltip/loader';

import { LoginForm } from './login-form';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://d34f95d1623547118799c55c31d311b6@sentry.ispsystem.net/15',
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 1.0,
});

applyPolyfills().then(() => {
  defineInputElements();
  defineButtonElements();
  defineSelectElements();
  defineTooltipElements();
});

document.addEventListener('DOMContentLoaded', function () {
  new LoginForm();
});
